<template>
  <v-col
    :key="transport.id"
    cols="12"
    class="my-4 rounded bordered"
  >
    <v-row>
      <v-col
        align-self="center"
        class="teal lighten-2 rounded-r"
      >
        <v-checkbox
          v-model="transport.selected"
          color="dark"
          hide-details
        />
      </v-col>
      <v-col
        v-for="header in headers"
        :key="`${index}-${header.value}`"
        align-self="center"
        width="100"
      >
        <div>{{ item[header.value] }}</div>
      </v-col>
      <v-col>
        <label>Припасы</label>
        <v-checkbox
          v-model="transport.supplies"
          :disabled="disabled"
          color="teal lighten-2"
          hide-details
        />
      </v-col>
      <v-col>
        <label>Товары</label>
        <v-checkbox
          v-model="transport.ware"
          :disabled="disabled"
          color="teal lighten-2"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <label>Кол-во пассажиров</label>
        <v-text-field
          v-model="transport.passenger_quantity"
          :disabled="disabled"
          dense
          hide-details
          outlined
          :background-color="color"
        />
      </v-col>
      <v-col cols="4">
        <label>Цель ввоза/вывоза</label>
        <v-autocomplete
          v-model="transport.purpose_code"
          auto-select-first
          :items="codes"
          :disabled="disabled"
          dense
          hide-details
          outlined
          :background-color="color"
        />
      </v-col>
      <v-col cols="8">
        <label>Запчасти при наличии</label>
        <v-textarea
          v-model="transport.transport_parts"
          rows="3"
          :disabled="disabled"
          dense
          hide-details
          outlined
          :background-color="color"
        />
      </v-col>
      <v-col cols="4">
        <label>Доп.сведения</label>
        <v-textarea
          v-model="transport.add_info"
          rows="3"
          :disabled="disabled"
          dense
          hide-details
          outlined
          :background-color="color"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  props:{
    headers:{
      type:Array,
      required:false,
    },
    common:{
      required:true,
      type:Boolean
    },
    item:{
      required:true,
      type:Object
    },
    index:{
      required:true,
      type:Number
    }
  },
  data:() => ({
    codes:[
      'Для начала перевозки на таможенной территории',
      'Для завершения перевозки на таможенной территории',
      'Для начала перевозки за пределы таможенной территории',
      'Для завершения перевозки за пределами таможенной территории',
    ],
    transport:{
      selected:false,
      supplies:false,
      ware:false,
      passenger_quantity:0,
      transport_parts:"",
      purpose_code:"",
      add_info:""
    }
  }),
  computed:{
    disabled(){
      return this.index > 0 ? this.common : false
    },
    color(){
      return this.disabled ? 'grey lighten-2' : 'white'
    }
  },
  watch:{
    item:{
      handler(){
        Object.keys(this.transport).forEach(key => {
          if(key in this.item){
            this.transport[key] = this.item[key]
          }
        })
      },
      deep:true,
      immediate:true
    },
    transport:{
      handler(value){
        this.$emit('update', {index: this.index, value:{
          ...this.item,
          ...value
        }})
      },
      deep:true
    }
  }
  
}

</script>
<style scoped>
.bordered{
  border:1px dashed #000
}
</style>