<template>
  <v-overlay
    :value="show"
  >
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        Таможенная декларация на транспортное средство
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <div class="grey lighten-2 rounded px-4 py-3">
          <v-checkbox
            v-model="common"
            label="Общая ТД на ТС"
            color="teal lighten-2"
            hide-details
            @change="onCommonChange"
          />
        </div>
        <div
          style="height: auto;max-height: 70vh"
          class="overflow-y-auto my-2"
        >
          <transport-declaration-item
            v-for="(item, index) in items"
            :key="item.id"
            :headers="headers"
            :index="index"
            :item="item"
            :common="common"
            @update="onTransportUpdate"
          />
        </div>
        <div class="d-flex justify-end mt-4">
          <v-btn
            color="#5CB7B1"
            elevation="0"
            :dark="hasSelectedVehicles"
            :disabled="!hasSelectedVehicles"
            @click="submit"
          >
            Скачать PDF
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";
import TransportDeclarationItem from "@/components/documents/transport-declaration/transport-declaration-item.vue";
import cloneDeep from "lodash.clonedeep";

export default {
  components: {TransportDeclarationItem},
  mixins: [modalWindowMixin],
  props:{
    namespace:{
      required:true,
      type:String,
    }
  },
  data: () => ({
    common:false,
    items: [],
    headers: [
      {
        text: "Номер",
        value: "number"
      },
      {
        text: "Страна",
        value: "country_letter"
      },
      {
        text: "Тип",
        value: "vehicle_type"
      },
      {
        text: "Марка",
        value: "mark"
      },
    ],
    requiredKeys:[
      "transport_id",
      "passenger_quantity",
      "supplies",
      "ware",
      "transport_parts",
      "purpose_code",
      "add_info"
    ]
  }),
  computed: {
    selected(){
      return this.$store.getters[`${this.namespace}/getSelected`]
    },
    hasSelectedVehicles(){
      return this.items.some(i => i.selected)
    },
  },
  watch: {
    show: {
      handler(nv) {
        if (nv === true) {
          this.setFields()
        }
      },
      immediate: true
    }
  },
  methods: {
    onTransportUpdate({index, value}){
      this.items.splice(index, 1,value)
    },
    onCommonChange(value){
      this.items = this.items.map((transport,index) => {
        if(index === 0){
          return {
            ...transport,
            selected: transport.selected ? true : value === true
          }
        }else {
          return {
            ...transport,
            passenger_quantity: 0,
            supplies: false,
            ware: false,
            transport_parts: "",
            purpose_code: "",
            add_info: "",
            selected: transport.selected ? true : value === true
          }
        }
      })
    },
    isQuantityValid(items){
      return !items.some(item => {
        return item.passenger_quantity ? Number.isNaN(Number.parseInt(item.passenger_quantity)) : false
      })
    },
    setFields() {
      this.transportItems = cloneDeep(this.selected.transports);
      const transports = this.selected.transports;
      this.items = transports.map(item => {
        const {id,declaration_id,country_letter, number, vehicle_type, transport_mark_code, transport_mark_name} = item
        return {
          selected: false,
          mark: [transport_mark_code,transport_mark_name].filter(i => !!i).join('-'),
          passenger_quantity: 0,
          supplies: false,
          ware: false,
          transport_parts: "",
          purpose_code: "",
          add_info: "",
          transport_id:id,
          id,
          country_letter,
          number,
          vehicle_type,
          declaration_id,
        }
      })
    },
    submit() {
      const transportsSelected = this.items.filter(i => i.selected)
      if(!this.isQuantityValid(transportsSelected)) return this.$error('Кол-во пассажиров должно быть числом')
      const {declaration_id} = transportsSelected.at(0)
      this.$store.dispatch(`${this.namespace}/downloadTransportDeclaration`, {
        id:declaration_id,
        items: transportsSelected.map(transport => transport.pick(this.requiredKeys)).map(transport => {
          return {
            ...transport,
            passenger_quantity: Number.parseInt(transport.passenger_quantity) || 0,
            transport_parts: transport.transport_parts ?? ""
          }
        }),
        common:this.common
      }).then((res) => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({
          data:res.data,
          type:'application/pdf',
          name
        })
      }).catch(() => this.$error())

    }
  }
}
</script>
